import React from 'react';
import advocateProfilePic from '../../../assets/images/advocates-details/venkat.jpg';

const AdvocateVenkatDetails = () => {
  return (
    <section className='team-details'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-lg-5'>
            <div className='team-details__content'>
              <h2 className='team-details__title'>Read my story</h2>
              <p className='team-details__text'>
                Mr. Pradeep did his LLB and graduated from Osmania University.
                He enrolled as an Advocate in the year 2018. He is specialised
                in the area of Civil laws. He is currently practising at Civil
                and Criminal Courts at Hyderabad.
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={advocateProfilePic} alt='' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'>
                  B. Venkat Durga Pradeep. BCOM., LLB.
                </h2>
                <p className='team-one__designation'>Advocate</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvocateVenkatDetails;
